import React from 'react';
import './ImageTextRow.css';

const ImageTextRow = ({ imageSrc = '/bra1.jpg', text }) => {
    return (
        <div className="image-text-row">
            <div className="image-container">
                <img src={imageSrc} alt="description" className="image" />
            </div>
            <div className="text-container">
                <h1>Transform Your Fitness Regime with Jump Rope: The Ultimate Cardio and Strength Training Tool</h1>
                <p>Jumping rope is an excellent way to enhance your fitness routine, offering significant benefits for your cardio, endurance, stamina, and speed. This simple yet effective exercise is not only a favorite among boxers, MMA fighters, and cross-trainers but also a versatile workout option for anyone looking to tone their body and improve overall health. By incorporating jump rope into your regular exercise regimen, you can achieve a well-rounded workout that targets multiple aspects of physical fitness.</p>
                <p>One of the primary advantages of jumping rope is its ability to improve cardiovascular health. This high-intensity exercise gets your heart rate up quickly, helping to strengthen the heart and lungs. Regular practice can lead to better endurance, allowing you to perform other physical activities with greater ease and for longer durations.</p>
                <p>In addition to cardiovascular benefits, jumping rope is an excellent way to build stamina and speed. The repetitive nature of the exercise trains your muscles to work efficiently, enhancing your ability to sustain physical activity over time. This can be particularly beneficial for athletes who require bursts of speed and endurance in their sports.</p>
                <p>In conclusion, jumping rope is a powerful and versatile exercise that can significantly enhance your fitness regime. Its benefits for cardio health, endurance, stamina, and muscle toning make it a valuable addition to any workout routine. Whether you're aiming to improve your athletic performance or simply seeking a fun and effective way to stay fit, jumping rope offers a comprehensive solution that can help you achieve your fitness goals.</p>
 </div>
        </div>
    );
};

export default ImageTextRow;
