// src/Home.js
import React, { useEffect, useState } from 'react';
import { firestore } from './firebase'; // Ensure your firebase.js is correctly configured
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import ImageTextRow from './ImageTextRow';

const Home = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true); // Add a loading state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      const q = query(collection(firestore, 'products'), orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      const productsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProducts(productsList);
      setLoading(false); // Set loading to false after data is fetched
    };

    fetchProducts();
  }, []);

  const handleProductClick = (product) => {
    navigate(`/product/${product.id}`, { state: { product } });
  };

  const handleViewAllClick = () => {
    navigate('/all-products');
  };

  // Show only the first 4 products
  const displayedProducts = products.slice(0, 4);

  return (
    <div className="home">
      <div className="row">
        {loading ? (
          // Show placeholders while loading
          <>
            <div className="card placeholder">
              <div className="placeholder-image"></div>
              <div className="placeholder-info">
                <div className="placeholder-line"></div>
                <div className="placeholder-line"></div>
                <div className="placeholder-line short"></div>
              </div>
            </div>
            <div className="card placeholder">
              <div className="placeholder-image"></div>
              <div className="placeholder-info">
                <div className="placeholder-line"></div>
                <div className="placeholder-line"></div>
                <div className="placeholder-line short"></div>
              </div>
            </div>
            <div className="card placeholder">
              <div className="placeholder-image"></div>
              <div className="placeholder-info">
                <div className="placeholder-line"></div>
                <div className="placeholder-line"></div>
                <div className="placeholder-line short"></div>
              </div>
            </div>
            <div className="card placeholder">
              <div className="placeholder-image"></div>
              <div className="placeholder-info">
                <div className="placeholder-line"></div>
                <div className="placeholder-line"></div>
                <div className="placeholder-line short"></div>
              </div>
            </div>
          </>
        ) : (
          // Show actual products after loading
          displayedProducts.map((product) => {
            const discount = ((product.cuttedPrice - product.productPrice) / product.cuttedPrice) * 100;
            return (
              <div className="card" key={product.id} onClick={() => handleProductClick(product)}>
                <img src={product.images[0]} alt={product.description} className="product-images" />
                <div className="product-info">
                  <h3>{product.productName}</h3> {/* Display product name */}

                  <p className="product-price">
                    Price. {product.productPrice}/- <span className="discount">({discount.toFixed(0)}% Off)</span> {/* Display current price and discount */}
                  </p>
                </div>
              </div>
            );
          })
        )}
      </div>
      {products.length > 4 && !loading && (
        <div className="view-all-container">
          <button className="view-all-button" onClick={handleViewAllClick}>View All</button>
        </div>
      )}

      <div style={{ marginTop: '20px' }}>
        <ImageTextRow />
      </div>

    </div>


  );
};

export default Home;
