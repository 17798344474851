// src/App.js
import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { CartProvider } from './CartContext';
import Home from './Home';
import ProductSection from './ProductSection';
import Cart from './Cart';
import Navbar from './Navbar';
import AllProducts from './AllProducts';
import Footer from './Footer';
import Header from './Header';
import Slider from './Slider';
import Order from './Order';
import UserOrderDetails from './UserOrderDetails';
import NotFound from './NotFound';
import AboutUs from './AboutUs'; // Import AboutUs component
import ContactUs from './ContactUs'; // Import ContactUs component
import ComingSoon from './ComingSoon'; // Import ComingSoon component

const App = () => {
  const location = useLocation();

  const handleCompleteOrder = (userData) => {
    console.log('Order completed with user data:', userData);
    // Implement order completion logic here
  };

  return (
    <CartProvider>
      <Header />
      <Navbar />

      {location.pathname === '/' && <Slider />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product/:id" element={<ProductSection />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/all-products" element={<AllProducts />} />
        <Route path="/order" element={<Order onCompleteOrder={handleCompleteOrder} />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/catalog" element={<ComingSoon />} />
        <Route path="/accessories-bra" element={<ComingSoon />} />
        <Route path="/blog" element={<ComingSoon />} />
        <Route path="/user-orders" element={<UserOrderDetails />} />
        <Route path="*" element={<NotFound />} /> {/* Catch-all route */}
      </Routes>

      <Footer />
    </CartProvider>
  );
};

export default App;
