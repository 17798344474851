import React, { useState, useEffect } from 'react';
import { firestore } from './firebase';
import { collection, getDocs, query, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './Offers.css';

const Offers = () => {
    const [offers, setOffers] = useState([]);
    const [products, setProducts] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOffers = async () => {
            const q = query(collection(firestore, 'offers'));
            const querySnapshot = await getDocs(q);
            const offersData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setOffers(offersData);

            // Fetch all product details based on selected product IDs
            const productIds = new Set();
            offersData.forEach(offer => {
                offer.selectedProducts.forEach(id => productIds.add(id));
            });

            const productDetails = {};
            await Promise.all(Array.from(productIds).map(async (productId) => {
                const productDoc = await getDoc(doc(firestore, 'products', productId));
                if (productDoc.exists()) {
                    productDetails[productId] = { id: productId, ...productDoc.data() };
                }
            }));

            setProducts(productDetails);
        };

        fetchOffers();
    }, []);

    const calculateTimeLeft = (endTime) => {
        const difference = +new Date(endTime.seconds * 1000) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                H: Math.floor((difference / (1000 * 60 * 60)) % 24),
                M: Math.floor((difference / 1000 / 60) % 60),
                S: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };

    const Timer = ({ endTime }) => {
        const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endTime));

        useEffect(() => {
            const timer = setTimeout(() => {
                setTimeLeft(calculateTimeLeft(endTime));
            }, 1000);

            return () => clearTimeout(timer);
        });

        const formatTime = (time) => (time < 10 ? `0${time}` : time);

        return (
            <div className="timer">
                <div className="timer-label">Offer ends in...</div>
                {Object.keys(timeLeft).length > 0 ? (
                    <>
                        <div className="timer-values">
                            <span>{formatTime(timeLeft.H)}</span>:
                            <span>{formatTime(timeLeft.M)}</span>:
                            <span>{formatTime(timeLeft.S)}</span>
                        </div>
                        <div className="timer-labels">
                            <span>HRS</span>
                            <span>MIN</span>
                            <span>SEC</span>
                        </div>
                    </>
                ) : (
                    <span>Offer expired</span>
                )}
            </div>
        );
    };

    const handleProductClick = (product) => {
        if (product && product.id) {
            navigate(`/product/${product.id}`, { state: { product } });
        } else {
            console.error('Product ID is undefined', product);
        }
    };

    return (
        <div className="offers-container">
            {offers.map((offer) => (
                <div key={offer.id} className="offer">
                    <h3>{offer.offerTitle}</h3>
                    <Timer endTime={offer.endTime} />
                    <div className="offer-products">
                        {offer.selectedProducts.map((productId) => {
                            const product = products[productId];
                            return product ? (
                                <div key={productId} className="product-card" onClick={() => handleProductClick(product)}>
                                    <img src={product.images[0]} alt={product.productName} className="product-img" />
                                    <div className="product-price">Price.{product.productPrice}/-</div>
                                    <h4 className="product-name">{product.productName}</h4>
                                    <div className="special-offer">
                                        <span>SPECIAL OFFER {offer.offerDetails}</span>
                                    </div>
                                </div>
                            ) : (
                                <p key={productId}>Loading...</p>
                            );
                        })}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Offers;
