import React, { useContext, useState, useEffect } from 'react';
import { CartContext } from './CartContext';
import { firestore } from './firebase';
import { doc, setDoc, getDoc, updateDoc, collection, addDoc } from 'firebase/firestore';
import './Order.css';
import { useAuth } from './AuthContext';
import { nanoid } from 'nanoid';
import { useNavigate } from 'react-router-dom';

const Order = ({ onCompleteOrder }) => {
    const { cartItems, clearCart, removeCartItem } = useContext(CartContext);
    const [orderNumber, setOrderNumber] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
    const [newAddress, setNewAddress] = useState({});
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState('');
    const { user, userData, setUserData } = useAuth();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isOrderLoading, setIsOrderLoading] = useState(false);
    const [isAddressSaving, setIsAddressSaving] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            if (user) {
                try {
                    const userDocRef = doc(firestore, 'Users', user.uid);
                    const userDoc = await getDoc(userDocRef);
                    if (userDoc.exists()) {
                        setUserData(userDoc.data());
                    } else {
                        console.error('No such document!');
                    }
                } catch (error) {
                    console.error('Error fetching user data: ', error);
                    setError('Error fetching user data');
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchUserData();
    }, [user, setUserData]);

    const calculateTotals = () => {
        let totalItems = 0;
        let totalPrice = 0;

        cartItems.forEach(item => {
            totalItems += item.quantity;
            totalPrice += item.currentPrice * item.quantity;
        });

        return {
            totalItems,
            totalPrice
        };
    };

    const totals = calculateTotals();

    const handleNewAddressChange = (e) => {
        const { name, value } = e.target;
        setNewAddress(prevState => ({ ...prevState, [name]: value }));
    };

    const validateAddress = () => {
        const errors = {};
        if (!newAddress.address) errors.address = 'Address is required';
        if (!newAddress.city) errors.city = 'City is required';
        if (!newAddress.state) errors.state = 'State is required';
        if (!newAddress.pincode || !/^\d{6}$/.test(newAddress.pincode)) errors.pincode = 'Valid Pincode is required';
        if (!newAddress.LandMark) errors.LandMark = 'LandMark is required';

        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleAddAddress = async () => {
        if (validateAddress()) { // Ensure validation is successful
            setIsAddressSaving(true);
            try {
                if (user && newAddress) {
                    const userDocRef = doc(firestore, 'Users', user.uid);
                    const userDoc = await getDoc(userDocRef);
                    if (userDoc.exists()) {
                        const existingData = userDoc.data();
                        const updatedAddresses = existingData.address || [];
                        updatedAddresses.push(newAddress);
                        await setDoc(userDocRef, { address: updatedAddresses }, { merge: true });
                        alert('New address added successfully');
                        setUserData(prevData => ({
                            ...prevData,
                            address: updatedAddresses
                        }));
                        // Reset the form fields
                        setNewAddress({});
                        setValidationErrors({});
                    }
                }
            } catch (error) {
                console.error('Error adding address: ', error);
                alert('Error adding address');
            } finally {
                setIsAddressSaving(false);
                setIsAddressModalOpen(false); // Close the modal after adding the address
            }
        }
    };

    const handleDeleteAddress = async (index) => {
        if (user) {
            const userDocRef = doc(firestore, 'Users', user.uid);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                const existingData = userDoc.data();
                const updatedAddresses = existingData.address.filter((_, addrIndex) => addrIndex !== index);
                await updateDoc(userDocRef, { address: updatedAddresses });
                alert('Address deleted successfully');
                setUserData(prevData => ({
                    ...prevData,
                    address: updatedAddresses
                }));
            }
        }
    };

    const generateOrderId = () => {
        return nanoid(8).toUpperCase();
    };

    const handleCompleteOrder = async () => {
        if (selectedAddress !== null && paymentMethod) {
            setIsOrderLoading(true);
            const orderId = generateOrderId();
            const orderData = {
                orderId,
                userId: user.uid,
                items: cartItems,
                totalItems: totals.totalItems,
                totalPrice: totals.totalPrice,
                address: userData.address[selectedAddress],
                paymentMethod,
                orderDate: new Date().toISOString(),
                status: 'Pending'
            };

            try {
                // Save the order to the user's subcollection in Firestore
                const userDocRef = doc(firestore, 'Users', user.uid);
                await addDoc(collection(userDocRef, 'orders'), orderData);

                // Clear the cart
                await clearCart();

                setOrderNumber(orderId);
                setIsModalOpen(true);
                setIsOrderLoading(false);
            } catch (error) {
                console.error('Error placing order: ', error);
                alert('Error placing order');
                setIsOrderLoading(false);
            }
        } else {
            alert('Please select an address and a payment method.');
        }
    };

    if (loading) {
        return <p>Loading user data...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className="checkout-order-page">
            <div className="checkout-order-row">
                <div className="checkout-order-title">
                    <h2>Delivery Details</h2>
                    <div className="checkout-order-content">
                        <div className="checkoout-order-details">
                            {userData ? (
                                <div>
                                    <p>{`Hello ${userData.firstName} ${userData.lastName}`}</p>
                                    <p>Mobile: {userData.mobile}</p>
                                    <div>
                                        {userData.address && userData.address.length > 0 ? (
                                            userData.address.map((addr, index) => (
                                                <div className="address-card" key={index}>
                                                    <label className="radio-container">
                                                        <input
                                                            type="radio"
                                                            name="address"
                                                            value={index}
                                                            checked={selectedAddress === index}
                                                            onChange={() => setSelectedAddress(index)}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                    <div className="address-info">
                                                        <p>Address: {addr.address}</p>
                                                        <p>{addr.city}, {addr.state}, {addr.pincode}</p>
                                                        <p>LandMark: {addr.LandMark}</p> {/* Displaying the LandMark */}
                                                    </div>
                                                    <span className="close" onClick={() => handleDeleteAddress(index)}>&times;</span>
                                                </div>
                                            ))
                                        ) : (
                                            <p>No addresses available.</p>
                                        )}
                                    </div>
                                    <button className="add-address-button" onClick={() => setIsAddressModalOpen(true)}>Add New Address</button>
                                </div>
                            ) : (
                                <p>Loading user data...</p>
                            )}
                        </div>
                        <div className="payment-method">
                            <h3>Payment Method</h3>
                            <label className="radio-container">
                                <input
                                    type="radio"
                                    name="paymentMethod"
                                    value="COD"
                                    checked={paymentMethod === 'COD'}
                                    onChange={() => setPaymentMethod('COD')}
                                />
                                <span className="checkmark"></span>
                                <label>Cash on Delivery</label>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="cart-summr">
                    <h2>Cart Summary</h2>
                    {cartItems.map((item, index) => (
                        <div className="cart-item" key={index}>
                            <img src={item.images[0]} alt={item.productName} className="cart-item-image" />
                            <div className="cart-item-details">
                                <h3>{item.productName}</h3>
                                <p>Color: {item.selectedColor}, Size: {item.selectedSize}</p>
                                <p>Price: Rs. {item.currentPrice}/-</p>
                                <p>Cut Price: Rs. {item.cuttedPrice}/-</p>
                                <p>Quantity: {item.quantity}</p>
                            </div>
                        </div>
                    ))}
                    <div className="totals">
                        <p>Total Items: {totals.totalItems}</p>
                        <p>Total Price: Rs. {totals.totalPrice}/-</p>
                    </div>
                    <button className="complete-order-button" onClick={handleCompleteOrder} disabled={isOrderLoading}>
                        {isOrderLoading ? 'Loading...' : 'Complete Order'}
                    </button>
                </div>
            </div>
            {isAddressModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setIsAddressModalOpen(false)}>×</span>
                        <h3>Add New Address</h3>
                        <input
                            type="text"
                            name="address"
                            placeholder="Address"
                            value={newAddress.address || ''}
                            onChange={handleNewAddressChange}
                        />
                        {validationErrors.address && <p className="error">{validationErrors.address}</p>}
                        <input
                            type="text"
                            name="city"
                            placeholder="City"
                            value={newAddress.city || ''}
                            onChange={handleNewAddressChange}
                        />
                        {validationErrors.city && <p className="error">{validationErrors.city}</p>}
                        <input
                            type="text"
                            name="state"
                            placeholder="State"
                            value={newAddress.state || ''}
                            onChange={handleNewAddressChange}
                        />
                        {validationErrors.state && <p className="error">{validationErrors.state}</p>}
                        <input
                            type="text"
                            name="pincode"
                            placeholder="Pincode"
                            value={newAddress.pincode || ''}
                            onChange={handleNewAddressChange}
                        />
                        {validationErrors.pincode && <p className="error">{validationErrors.pincode}</p>}
                        <input
                            type="text"
                            name="LandMark"
                            placeholder="LandMark"
                            value={newAddress.LandMark || ''}
                            onChange={handleNewAddressChange}
                        />
                        {validationErrors.LandMark && <p className="error">{validationErrors.LandMark}</p>}
                        <button onClick={handleAddAddress} disabled={isAddressSaving}>
                            {isAddressSaving ? 'Loading...' : 'Save Address'}
                        </button>
                    </div>
                </div>
            )}
            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Thank you for shopping with us!</h3>
                        <p>Your order number is: {orderNumber}</p>
                        <button onClick={() => navigate('/user-orders')}>OK</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Order;
