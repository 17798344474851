import React, { useEffect, useState } from 'react';
import { firestore } from './firebase'; // Ensure your firebase.js is correctly configured
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './AllProducts.css';

const AllProducts = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProducts = async () => {
            const q = query(collection(firestore, 'products'), orderBy('createdAt', 'desc'));
            const querySnapshot = await getDocs(q);
            const productsList = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setProducts(productsList);
            setLoading(false); // Data has been fetched, stop loading
        };

        fetchProducts();
    }, []);

    const handleProductClick = (product) => {
        navigate(`/product/${product.id}`, { state: { product } });
    };

    return (
        <div className="all-products">
            <div className="row">
                {loading ? (
                    Array(4).fill().map((_, index) => (
                        <div className="card placeholder" key={index}>
                            <div className="product-images placeholder-image"></div>
                            <div className="product-info">
                                <h3 className="placeholder-text">Loading...</h3>
                                <p className="placeholder-text">Loading description...</p>
                                <p className="product-price placeholder-text">Loading price...</p>
                            </div>
                        </div>
                    ))
                ) : (
                    products.map((product) => {
                        const discount = ((product.cuttedPrice - product.productPrice) / product.cuttedPrice) * 100;
                        return (
                            <div className="card" key={product.id} onClick={() => handleProductClick(product)}>
                                <img src={product.images[0]} alt={product.description} className="product-images" />
                                <div className="product-info">
                                    <h3>{product.productName}</h3>
                                    <p className="description">{product.description}</p>
                                    <p className="product-price">
                                        ₹{product.productPrice} <span className="cutted-price">₹{product.cuttedPrice}</span> <span className="discount">({discount.toFixed(0)}% OFF)</span>
                                    </p>
                                    <p className="inclusive-taxes">Inclusive of all taxes</p>
                                    <p className="rating">* rating star {product.rating} ({product.reviewsCount} Reviews)</p>
                                </div>
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    );
};

export default AllProducts;
