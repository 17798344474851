import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CheckoutModal.css';
import { AuthContext } from './AuthContext';

const CheckoutModal = ({ isOpen, onRequestClose }) => {
  const [isLogin, setIsLogin] = useState(false);
  const { login, registerUser, loading } = useContext(AuthContext);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    address: '',
    state: '',
    city: '',
    pincode: '',
    landmark: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSwitch = () => {
    setIsLogin(!isLogin);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (isLogin) {
        await login(formData.email); // No password needed
        navigate('/order'); // Navigate to Order.js after login
      } else {
        await registerUser(formData);
        navigate('/order'); // Navigate to Order.js after registration
      }
      onRequestClose();
    } catch (error) {
      console.error("Authentication failed", error);
      setErrors({ form: error.message });
    }
  };

  if (!isOpen) {
    return null; // Do not render the modal if it's not open
  }

  return (
    <div className="model-overlay" onClick={onRequestClose}>
      <div className="model" onClick={(e) => e.stopPropagation()}>
        <h2>{isLogin ? 'Login' : 'Register'}</h2>
        <form onSubmit={handleSubmit}>
          {isLogin ? (
            <>
              <div className="form-row">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="submit" className="submit-btn" disabled={loading}>
                {loading ? 'Logging in...' : 'Login'}
              </button>
              <div className="social-login">
                <button className="social-btn google-btn">Login with Google</button>
                <button className="social-btn facebook-btn">Login with Facebook</button>
                <button className="social-btn mobile-btn">Login with Mobile Number</button>
              </div>
              <p className="switch-text" onClick={handleSwitch}>Don't have an account? Register</p>
            </>
          ) : (
            <>
              <div className="form-row">
                <input
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-row">
                <input
                  type="text"
                  placeholder="Mobile Number"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-row">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-row">
                <input
                  type="text"
                  placeholder="Address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-row">
                <input
                  type="text"
                  placeholder="City"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  placeholder="State"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  placeholder="Pincode"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-row">
                <input
                  type="text"
                  placeholder="Landmark"
                  name="landmark"
                  value={formData.landmark}
                  onChange={handleChange}
                />
              </div>
              <button type="submit" className="submit-btn" disabled={loading}>
                {loading ? 'Registering...' : 'Register'}
              </button>
              <p className="switch-text" onClick={handleSwitch}>Already have an account? Login</p>
            </>
          )}
        </form>
        {errors.form && <p className="error-text">{errors.form}</p>}
      </div>
    </div>
  );
};

export default CheckoutModal;
