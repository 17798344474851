import React, { useState, useEffect } from 'react';
import './AddBanner.css';

const AddBanner = () => {
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = process.env.PUBLIC_URL + '/ban1.png';
        img.onload = () => setImageLoaded(true);
    }, []);

    return (
        <div className="add-banner-container">
            {!imageLoaded && (
                <img
                    src="https://via.placeholder.com/1200x300?text=Loading+Banner..."
                    alt="Placeholder"
                    className="add-banner-image"
                />
            )}
            {imageLoaded && (
                <img
                    src={process.env.PUBLIC_URL + '/ban3.png'} // Path to your image in the public folder
                    alt="Add Banner"
                    className="add-banner-image"
                />
            )}
        </div>
    );
};

export default AddBanner;
