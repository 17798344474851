import React, { useState, useContext } from 'react';
import { AuthContext } from './AuthContext';
import ModalForm from './ModalForm';
import './Tabs.css';
import { FaStar } from 'react-icons/fa';

const Tabs = ({ product }) => {
    const [activeTab, setActiveTab] = useState('review');
    const [reviewText, setReviewText] = useState('');
    const [rating, setRating] = useState(0);
    const { isLoggedIn } = useContext(AuthContext);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const reviews = [
        { user: 'User1', rating: 4, text: 'Great product!' },
        { user: 'User2', rating: 5, text: 'Absolutely loved it!' },
        // Add more reviews as needed
    ];

    const handleSubmitReview = () => {
        if (isLoggedIn) {
            // Handle review submission logic here
            console.log('Review submitted:', reviewText, rating);
            // Reset review form
            setReviewText('');
            setRating(0);
        } else {
            setModalIsOpen(true);
        }
    };

    const handleStarClick = (starValue) => {
        setRating(starValue);
    };

    return (
        <div className="tabs-container">
            <div className="tabs">
                <button
                    className={`tab ${activeTab === 'review' ? 'active' : ''}`}
                    onClick={() => setActiveTab('review')}
                >
                    Review
                </button>
                <button
                    className={`tab ${activeTab === 'description' ? 'active' : ''}`}
                    onClick={() => setActiveTab('description')}
                >
                    Additional Description
                </button>
                <button
                    className={`tab ${activeTab === 'return' ? 'active' : ''}`}
                    onClick={() => setActiveTab('return')}
                >
                    Return
                </button>
            </div>
            <div className="content">
                {activeTab === 'review' && (
                    <div>
                        {reviews.map((review, index) => (
                            <div key={index} className="review">
                                <p>
                                    <strong>{review.user}</strong> rated: 
                                    {[...Array(5)].map((_, i) => (
                                        <FaStar key={i} color={i < review.rating ? '#ffc107' : '#e4e5e9'} />
                                    ))}
                                </p>
                                <p>{review.text}</p>
                            </div>
                        ))}
                        <div className="review-form">
                            <div>
                                <label>Rating:</label>
                                <div className="stars">
                                    {[...Array(5)].map((_, i) => (
                                        <FaStar
                                            key={i}
                                            size={24}
                                            onClick={() => handleStarClick(i + 1)}
                                            color={i < rating ? '#ffc107' : '#e4e5e9'}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div>
                                <label>Review:</label>
                                <textarea
                                    value={reviewText}
                                    onChange={(e) => setReviewText(e.target.value)}
                                />
                            </div>
                            <button onClick={handleSubmitReview}>Submit Review</button>
                        </div>
                    </div>
                )}
                {activeTab === 'description' && <div>Here is the additional description content.</div>}
                {activeTab === 'return' && <div>Here is the return information.</div>}
            </div>
            <ModalForm isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)} product={product} />
        </div>
    );
};

export default Tabs;
