import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import './UserOrderDetails.css';

const UserOrderDetails = () => {
  const { user, fetchUserOrders } = useAuth();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      if (user) {
        try {
          const fetchedOrders = await fetchUserOrders();
          console.log('Fetched Orders:', fetchedOrders);
          setOrders(fetchedOrders);
        } catch (error) {
          console.error('Error fetching orders:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchOrders();
  }, [user, fetchUserOrders]);

  const calculateExpectedDelivery = (orderDate) => {
    // Check if orderDate is a Firestore Timestamp
    let deliveryDate;
    if (orderDate && typeof orderDate.toDate === 'function') {
      deliveryDate = new Date(orderDate.toDate()); // Firestore Timestamp case
    } else {
      deliveryDate = new Date(orderDate); // Handle Date or string case
    }

    deliveryDate.setDate(deliveryDate.getDate() + 3);
    return deliveryDate.toLocaleDateString();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="user-order-details">
      {orders.length === 0 ? (
        <p>No orders found.</p>
      ) : (
        orders.map(order => (
          <div key={order.id} className="user-order-card">
            <h2>Order ID: {order.orderId}</h2>
            <p>Order Date: {new Date(order.orderDate).toLocaleDateString()}</p>
            <p>Expected Delivery: {calculateExpectedDelivery(order.orderDate)}</p>
            <p>Payment Method: {order.cashOnDelivery ? 'Cash on Delivery' : 'Prepaid'}</p>

            {order.items && order.items.length > 0 ? (
              order.items.map((item, index) => (
                <div key={index} className="user-order-row">
                  <div className="user-product-details">
                    <div className="user-order-details-card">
                      <div className="user-card-content">
                        <div className="user-order-image">
                          <img
                            src={item.images || 'https://via.placeholder.com/150'}
                            alt="Product"
                          />
                        </div>
                        <div className="text-content">
                          <h3>{item.productName || 'Product Name'}</h3>
                          <p>Quantity: {item.quantity || 'N/A'}</p>
                          <p>Price: {item.productPrice || 'N/A'}</p>
                          <p>Color: {item.selectedColor || 'N/A'}</p>
                          <p>Size: {item.selectedSize || 'N/A'}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No items found in this order.</p>
            )}

            <div className="order-total-price">
              <p>Total Price: {order.items.reduce((total, item) => total + parseFloat(item.productPrice) * item.quantity, 0).toFixed(2)}</p>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default UserOrderDetails;
