import React, { createContext, useState, useEffect, useContext } from 'react';
import { auth, firestore } from './firebase'; // Adjust the import path as needed
import {
    onAuthStateChanged,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut
} from 'firebase/auth';
import {
    setDoc,
    doc,
    getDoc,
    collection,
    getDocs,
    addDoc,
    query,
    orderBy
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setIsLoggedIn(true);
                setUser(user);
                localStorage.setItem('isLoggedIn', 'true');

                // Fetch user data from Firestore
                const userDoc = await getDoc(doc(firestore, 'Users', user.uid));
                if (userDoc.exists()) {
                    setUserData(userDoc.data());
                } else {
                    console.log('No such document!');
                }
            } else {
                setIsLoggedIn(false);
                setUser(null);
                setUserData(null);
                localStorage.removeItem('isLoggedIn');
            }
        });
        return () => unsubscribe();
    }, []);

    const login = async (email, password) => {
        setLoading(true);
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            setIsLoggedIn(true);
            setUser(user);
            localStorage.setItem('isLoggedIn', 'true');

            // Fetch user data from Firestore
            const userDoc = await getDoc(doc(firestore, 'Users', user.uid));
            if (userDoc.exists()) {
                setUserData(userDoc.data());
            } else {
                console.log('No such document!');
            }
        } catch (error) {
            console.error('Login error:', error);
        } finally {
            setLoading(false);
        }
    };

    const signup = async (formData) => {
        setLoading(true);
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
            const user = userCredential.user;

            // Save user data to Firestore
            await setDoc(doc(firestore, 'Users', user.uid), {
                firstName: formData.firstName,
                lastName: formData.lastName,
                mobile: formData.mobile,
                email: formData.email,
                address: [
                    {
                        address: formData.address,
                        state: formData.state,
                        city: formData.city,
                        pincode: formData.pincode,
                        landmark: formData.landmark
                    }
                ]
            });
            setIsLoggedIn(true);
            setUser(user);
            localStorage.setItem('isLoggedIn', 'true');

            // Set the user data
            setUserData({
                firstName: formData.firstName,
                lastName: formData.lastName,
                mobile: formData.mobile,
                address: [
                    {
                        address: formData.address,
                        state: formData.state,
                        city: formData.city,
                        pincode: formData.pincode,
                        landmark: formData.landmark
                    }
                ]
            });
        } catch (error) {
            console.error('Signup error:', error);
        } finally {
            setLoading(false);
        }
    };

    const logout = async () => {
        try {
            await signOut(auth);
            setIsLoggedIn(false);
            setUser(null);
            setUserData(null);
            localStorage.removeItem('isLoggedIn');
        } catch (error) {
            console.error('Logout error:', error);
        }
    };
    const registerUser = async (formData) => {
        setLoading(true);
        try {
            const { email, firstName, lastName, mobile, address, city, state, pincode, landmark } = formData;

            // Prepare address object
            const addressData = {
                address,
                city,
                state,
                pincode,
                landmark,
            };

            // Save user data to Firestore
            await setDoc(doc(firestore, 'Users', email), { // Using email as the document ID
                firstName,
                lastName,
                email,
                mobile,
                address: [addressData],
            });

            setLoading(false);
        } catch (error) {
            console.error('Error registering user:', error);
            setLoading(false);
            throw error;
        }
    };






    const registerUserAndPlaceOrder = async (formData, product, setErrors) => {
        setLoading(true);
        try {
            const { email, password, firstName, lastName } = formData;

            // Create user
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const userId = userCredential.user.uid;

            // Prepare address array
            const addressArray = [
                {
                    address: formData.address,
                    landmark: formData.landmark,
                    state: formData.state,
                    city: formData.city,
                    pincode: formData.pincode
                }
            ];

            // Save user data to Firestore
            await setDoc(doc(firestore, 'Users', userId), {
                firstName,
                lastName,
                email,
                address: addressArray,
                mobile: formData.mobile
            });

            // Prepare items array with the product information
            const itemsArray = [
                {
                    productName: product.productName,
                    productPrice: product.productPrice,
                    selectedSize: formData.size,
                    selectedColor: formData.color,
                    images: product.images[0], // Use the first image or any preferred image
                    quantity: formData.quantity,
                    cashOnDelivery: formData.cashOnDelivery,
                    Status: 'Pendign'
                }
            ];

            // Prepare order data
            const orderData = {
                orderId: uuidv4().slice(0, 8).toUpperCase(), // Generate an 8-digit alphanumeric order ID
                items: itemsArray, // Store product details in the items array
                address: addressArray, // Use the prepared address array
                orderDate: new Date()
            };

            // Place an order in the 'orders' subcollection
            await addDoc(collection(doc(firestore, 'Users', userId), 'orders'), orderData);

            setLoading(false);
            return orderData.orderId; // Return the order ID
        } catch (error) {
            setLoading(false);

            if (error.code === 'auth/email-already-in-use') {
                setErrors({ email: 'This email is already in use. Please use a different email or log in.' });
            } else {
                console.error('Error registering and placing order:', error);
                setErrors({ general: 'An error occurred during registration. Please try again later.' });
            }
            throw error;
        }
    };




    const fetchOrders = async () => {
        console.log('Fetching orders...');
        try {
            const ordersCollection = collection(firestore, 'Orders');
            console.log('Orders Collection:', ordersCollection);

            const ordersSnapshot = await getDocs(ordersCollection);
            console.log('Orders Snapshot:', ordersSnapshot);

            if (ordersSnapshot.empty) {
                console.log('No matching documents.');
                return [];
            }

            const ordersList = ordersSnapshot.docs.map(doc => {
                const data = {
                    id: doc.id,
                    ...doc.data()
                };
                console.log('Document Data:', data);
                return data;
            });

            console.log('Orders List:', ordersList);
            return ordersList;
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };

    const fetchUserOrders = async () => {
        setLoading(true);
        try {
            if (!user) throw new Error("User not logged in");

            const userDocRef = doc(firestore, 'Users', user.uid);
            const ordersQuery = query(collection(userDocRef, 'orders'), orderBy('orderDate', 'desc'));
            const ordersSnapshot = await getDocs(ordersQuery);

            const orders = ordersSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            return orders;
        } catch (error) {
            console.error("Error fetching user orders:", error);
            throw error;
        } finally {
            setLoading(false);
        }
    };


    return (
        <AuthContext.Provider value={{
            isLoggedIn,
            user,
            userData,
            setUserData,
            login,
            signup,
            logout,
            fetchOrders,
            registerUser,
            registerUserAndPlaceOrder,
            fetchUserOrders,
            loading
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
