import React, { useState, useEffect } from 'react';
import './Header.css';

const Header = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const announcements = [
    'Use Code "FIRST5" & Get 5% Extra OFF',
    '!!!SUPER DUPER SALE!!!',
    'WELCOME TO OUR STORE',
  ];

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % announcements.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(slideInterval);
  }, [announcements.length]);

  const goToPreviousSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + announcements.length) % announcements.length);
  };

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % announcements.length);
  };

  return (
    <div className='header-hide'>
      <div className="utility-bar color-scheme-4 gradient utility-bar--bottom-border">
        <div className="page-width utility-bar__grid">
          <div className="announcement-bar" role="region" aria-roledescription="Carousel" aria-label="Announcement bar">
            <div className="announcement-bar-slider slider-buttons">
              <button
                type="button"
                className="slider-button slider-button--prev"
                name="previous"
                aria-label="Previous announcement"
                onClick={goToPreviousSlide}
              >
                <svg aria-hidden="true" focusable="false" className="icon icon-arrow-left" viewBox="0 0 10 10">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.646 1.646a.5.5 0 010 .708L3.707 5l2.939 2.939a.5.5 0 11-.708.708l-3.646-3.646a.5.5 0 010-.708l3.646-3.646a.5.5 0 01.708 0z"
                    fill="currentColor"
                  ></path>
                </svg>
              </button>
              <div
                className="grid grid--1-col slider slider--everywhere"
                aria-live="polite"
                aria-atomic="true"
                data-autoplay="true"
                data-speed="5"
              >
                {announcements.map((announcement, index) => (
                  <div
                    key={index}
                    className={`slideshow__slide slider__slide grid__item grid--1-col ${index === currentSlide ? 'active' : ''
                      }`}
                    role="group"
                    aria-roledescription="Announcement"
                    aria-label={`${index + 1} of ${announcements.length}`}
                    aria-hidden={index !== currentSlide}
                  >
                    <div className="announcement-bar__announcement" role="region" aria-label="Announcement">
                      <p className="announcement-bar__message h5">
                        <span>{announcement}</span>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <button
                type="button"
                className="slider-button slider-button--next"
                name="next"
                aria-label="Next announcement"
                onClick={goToNextSlide}
              >
                <svg aria-hidden="true" focusable="false" className="icon icon-arrow-right" viewBox="0 0 10 10">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.354 1.646a.5.5 0 000 .708L6.293 5l-2.939 2.939a.5.5 0 10.708.708l3.646-3.646a.5.5 0 000-.708L4.062 1.646a.5.5 0 00-.708 0z"
                    fill="currentColor"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <div className="localization-wrapper"></div>
        </div>
      </div>
    </div>
  );
};

export default Header;
