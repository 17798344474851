import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Slider.css'; // Create this CSS file to style the slider
import Offers from './Offers';
import AddBanner from './AddBanner';

const SliderComponent = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div>
      <div className="slider-container">
        <Slider {...settings}>
          <div>
            <img src={`${process.env.PUBLIC_URL}/ban5.jpg`} alt="Image 1" className="slider-image" />
          </div>
          <div>
            <img src={`${process.env.PUBLIC_URL}/ban4.png`} alt="Image 2" className="slider-image" />
          </div>
          <div>
            <img src={`${process.env.PUBLIC_URL}/ban1.png`} alt="Image 3" className="slider-image" />
          </div>
        </Slider>
      </div>
      <Offers />
      <AddBanner />
    </div>
  );
};

export default SliderComponent;
