// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { collection, setDoc, query, where, getDocs, addDoc, doc } from 'firebase/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyBf4_2zT3i5MuJei9spTx5v1Mqf3--N84A",
  authDomain: "trading-7a77b.firebaseapp.com",
  projectId: "trading-7a77b",
  storageBucket: "trading-7a77b.appspot.com",
  messagingSenderId: "824330502330",
  appId: "1:824330502330:web:96a26b7f25008cd73a8283",
  measurementId: "G-PQDV206FZB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore, Storage and Auth
const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { firestore, storage, auth };
