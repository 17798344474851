// src/Navbar.js
import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';
import { CartContext } from './CartContext';
import { AuthContext } from './AuthContext';
import { ReactComponent as SearchIcon } from './icons/search.svg';
import { ReactComponent as CartIcon } from './icons/cart.svg';
import { ReactComponent as UserIcon } from './icons/user.svg'; // Ensure correct import path
import logo from './icons/logo.png'; // Ensure correct import path

const Navbar = () => {
  const { cartItems, clearCart } = useContext(CartContext);
  const { isLoggedIn, user, logout } = useContext(AuthContext);
  const cartItemCount = cartItems.length;
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSearchClick = () => {
    setShowSearchInput(!showSearchInput);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    navigate('/all-products', { state: { query: searchQuery } });
  };

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLogout = () => {
    logout();

    navigate('/');
  };

  const getUserInitial = () => {
    if (user && user.displayName) {
      return user.displayName.charAt(0).toUpperCase();
    } else if (user && user.email) {
      return user.email.charAt(0).toUpperCase();
    }
    return '';
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar__logo">
          <Link to="/">
            <img src={logo} alt="Logo" className="logo-image" />
          </Link>
        </div>
        <div className="navbar__links-icons">
          <ul className="navbar__links">
            <li><Link to="/">HOME</Link></li>
            <li><Link to="/catalog">NEW ARRIVALS</Link></li>
            <li><Link to="/accessories-bra">BRA ACCESSORIES</Link></li>
            <li><Link to="/blog">BLOG</Link></li>
            <li><Link to="/about-us">ABOUT US</Link></li> {/* Updated Link */}
            <li><Link to="/contact-us">CONNECT US</Link></li> {/* Updated Link */}
          </ul>
          <div className="navbar__icons">
            {showSearchInput && (
              <form onSubmit={handleSearchSubmit} className="search-form">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder="Search products"
                  className="search-input"
                />
              </form>
            )}
            <button onClick={handleSearchClick} aria-label="Search" className="search-button">
              <SearchIcon />
            </button>
            <Link to="/cart" aria-label="Cart" className="cart-icon">
              <CartIcon />
              {cartItemCount > 0 && <span className="cart-badge">{cartItemCount}</span>}
            </Link>
            {isLoggedIn && (
              <div className="user-dropdown" ref={dropdownRef}>
                <button onClick={handleDropdownToggle} aria-label="User" className="user-button">
                  <UserIcon className="user-avatar" />
                </button>
                {showDropdown && (
                  <ul className="dropdown-menu">
                    <li><Link to="/profile" className="dropdown-link">Your Profile</Link></li>
                    <div className="dropdown-divider"></div>
                    <li><Link to="/user-orders" className="dropdown-link">Your Orders</Link></li>
                    <div className="dropdown-divider"></div>
                    <li><a onClick={handleLogout} className="dropdown-link">Logout</a></li>
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
