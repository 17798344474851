// src/Footer.js
import React from 'react';
import './Footer.css'; // Make sure to create and style this CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-column address-column">
          <div className="contact-details">
            <h2>Contact Us</h2>
            <div className="contact-item">
              <i className="fas fa-phone-alt"></i>
              <p>+91 926 612 7640</p>
            </div>
            <div className="contact-item">
              <i className="fas fa-envelope"></i>
              <p>support_zivamisportsbra@gmail.com</p>
            </div>
            <div className="contact-item">
              <i className="fas fa-map-marker-alt"></i>
              <p>Plot No-17/B Sector 85 A Block Adore Market Shop No- B5 Faridabad Haryana - 121003</p>
              
            </div>
          </div>
        </div>
        <div className="footer-column subscribe-column">
          <h2>SUBSCRIBE TO OUR EMAILS</h2>
          <p>Be the first to know about new collections and exclusive offers.</p>
          <input type="email" placeholder="Enter your email" className="email-input" />
          <button className="subscribe-button">Subscribe</button>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Your Store. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
