// src/ComingSoon.js
import React from 'react';
import './ComingSoon.css';

const ComingSoon = () => {
    return (
        <div className="coming-soon">
            <h1>Coming Soon</h1>
            <p>We're working hard to bring you new content. Stay tuned!</p>
        </div>
    );
};

export default ComingSoon;
