import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './ModalForm.css';
import { firestore } from './firebase';
import { collection, addDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid'; // Import UUID library
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

const ModalForm = ({ isOpen, onRequestClose, product }) => {
  const navigate = useNavigate(); // Initialize useNavigate for redirection

  const initialFormData = {
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    address: '',
    state: '',
    city: '',
    pincode: '',
    landmark: '',
    size: product?.sizes?.[0] || '',
    color: product?.colors?.[0] || '',
    quantity: 1,
    cashOnDelivery: false,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // State to handle loading

  useEffect(() => {
    if (product) {
      setFormData((prevData) => ({
        ...prevData,
        size: product.sizes[0] || prevData.size,
        color: product.colors[0] || prevData.color,
      }));
    }
  }, [product]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, cashOnDelivery: e.target.checked });
  };

  const handleColorSelect = (color) => {
    setFormData({ ...formData, color });
  };

  const validateForm = () => {
    let errors = {};
    let valid = true;

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobilePattern = /^[6-9]\d{9}$/;
    const pincodePattern = /^\d{6}$/;

    if (!formData.email) {
      valid = false;
      errors.email = 'Email is required';
    } else if (!emailPattern.test(formData.email)) {
      valid = false;
      errors.email = 'Invalid email format';
    }

    if (!formData.firstName) {
      valid = false;
      errors.firstName = 'First Name is required';
    }
    if (!formData.lastName) {
      valid = false;
      errors.lastName = 'Last Name is required';
    }
    if (!formData.mobile) {
      valid = false;
      errors.mobile = 'Mobile Number is required';
    } else if (!mobilePattern.test(formData.mobile)) {
      valid = false;
      errors.mobile = 'Invalid mobile number format';
    }
    if (!formData.address) {
      valid = false;
      errors.address = 'Address is required';
    }
    if (!formData.state) {
      valid = false;
      errors.state = 'State is required';
    }
    if (!formData.city) {
      valid = false;
      errors.city = 'City is required';
    }
    if (!formData.pincode) {
      valid = false;
      errors.pincode = 'Pincode is required';
    } else if (!pincodePattern.test(formData.pincode)) {
      valid = false;
      errors.pincode = 'Invalid pincode format';
    }
    if (!formData.landmark) {
      valid = false;
      errors.landmark = 'Landmark is required';
    }
    if (!formData.size) {
      valid = false;
      errors.size = 'Size is required';
    }
    if (!formData.color) {
      valid = false;
      errors.color = 'Color is required';
    }
    if (!formData.cashOnDelivery) {
      valid = false;
      errors.cashOnDelivery = 'Cash on Delivery option must be selected';
    }

    setErrors(errors);
    return valid;
  };

 const handleFormSubmit = async (event) => {
  event.preventDefault();
  if (validateForm()) {
    setLoading(true); // Set loading state to true

    // Generate a unique 8-character alphanumeric order number
    const orderNo = uuidv4().slice(0, 8).toUpperCase();

    console.log('Product:', product); // Debugging: Log the product to check if it's passed correctly

    if (!product || !product.id) {
      alert('Product information is missing. Please try again.');
      setLoading(false);
      return;
    }

    try {
      // Add user details to the 'Users' collection
      const userRef = await addDoc(collection(firestore, 'Users'), {
        firstName: formData.firstName,
        lastName: formData.lastName,
        mobile: formData.mobile,
        email: formData.email,
        address: formData.address,
        state: formData.state,
        city: formData.city,
        pincode: formData.pincode,
        landmark: formData.landmark,
      });

      // Add order details to the 'orders' subcollection within the user document
      await addDoc(collection(firestore, `Users/${userRef.id}/orders`), {
        productId: product.id,
        size: formData.size,
        color: formData.color,
        quantity: formData.quantity,
        cashOnDelivery: formData.cashOnDelivery,
        productPrice: product.productPrice,
        orderDate: new Date(),
        orderNo: orderNo, // Add order number to the order document
      });

      alert(`Order placed successfully! Your Order No is ${orderNo}`);

      // After clicking OK on the alert, navigate to home and reset form
      setFormData(initialFormData); // Reset form
      navigate('/'); // Redirect to home page

      onRequestClose(); // Close the modal
    } catch (error) {
      console.error('Error saving order:', error);
      alert('An error occurred while placing the order.');
    } finally {
      setLoading(false); // Reset loading state after the process is done
    }
  }
};


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal-form"
      overlayClassName="overlay"
    >
      <div className="modal-content-form">
        <div className="modal-head">
          <h2>Place Order</h2>
          <button className="close-button" onClick={onRequestClose}>
            &times;
          </button>
        </div>
        <div className="modal-body">
          <div className="image-column">
            <div className="image-pro">
              {product && product.images && product.images[0] ? (
                <img src={product.images[0]} alt={product.productName} />
              ) : (
                <div className="placeholder-image">
                  <p>Product image not available</p>
                </div>
              )}
            </div>

            {product && (
              <>
                <div className="product-details">
                  <p>
                    <strong>{product.productName}</strong>
                  </p>
                  <div className="price-align">
                    <p>
                      <strong>MRP:</strong> {product.cuttedPrice}
                    </p>
                    <p>
                      <strong>Price:</strong> {product.productPrice}
                    </p>
                    <p>
                      <strong>Discount:</strong> (20% Off)
                    </p>
                  </div>

                  <div className="product-qty-align">
                    <div className="product-sizes">
                      <label htmlFor="size">Size:</label>
                      <select
                        id="size"
                        name="size"
                        value={formData.size}
                        onChange={handleChange}
                        required
                      >
                        {product.sizes.map((size, index) => (
                          <option key={index} value={size}>
                            {size}
                          </option>
                        ))}
                      </select>
                      {errors.size && <p className="error">{errors.size}</p>}
                    </div>
                    <div className="product-quantity">
                      <label htmlFor="quantity">Qty:</label>
                      <input
                        type="number"
                        id="quantity"
                        name="quantity"
                        value={formData.quantity}
                        onChange={handleChange}
                        min="1"
                        required
                      />
                    </div>
                  </div>

                  <div className="color-palette">
                    {product.colors.map((color, index) => (
                      <span
                        key={index}
                        className={`color-circle ${formData.color === color ? 'selected' : ''
                          }`}
                        style={{ backgroundColor: color }}
                        onClick={() => handleColorSelect(color)}
                      />
                    ))}
                    {errors.color && <p className="error">{errors.color}</p>}
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="form-column">
            <form onSubmit={handleFormSubmit}>
              <div className="form-group-row">
                <div className="form-group">
                  <label>First Name:</label>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                  {errors.firstName && <p className="error">{errors.firstName}</p>}
                </div>
                <div className="form-group">
                  <label>Last Name:</label>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                  {errors.lastName && <p className="error">{errors.lastName}</p>}
                </div>
              </div>

              <div className="form-group-row">
                <div className="form-group">
                  <label>Mobile:</label>
                  <input
                    type="text"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    required
                  />
                  {errors.mobile && <p className="error">{errors.mobile}</p>}
                </div>
                <div className="form-group">
                  <label>Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  {errors.email && <p className="error">{errors.email}</p>}
                </div>
              </div>

              <div className="form-group-row">
                <div className="form-group">
                  <label>Address:</label>
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    required
                  />
                  {errors.address && <p className="error">{errors.address}</p>}
                </div>
              </div>

              <div className="form-group-row">
                <div className="form-group">
                  <label>State:</label>
                  <input
                    type="text"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    required
                  />
                  {errors.state && <p className="error">{errors.state}</p>}
                </div>
                <div className="form-group">
                  <label>City:</label>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    required
                  />
                  {errors.city && <p className="error">{errors.city}</p>}
                </div>
              </div>

              <div className="form-group-row">
                <div className="form-group">
                  <label>Pincode:</label>
                  <input
                    type="text"
                    name="pincode"
                    value={formData.pincode}
                    onChange={handleChange}
                    required
                  />
                  {errors.pincode && <p className="error">{errors.pincode}</p>}
                </div>
                <div className="form-group">
                  <label>Landmark:</label>
                  <input
                    type="text"
                    name="landmark"
                    value={formData.landmark}
                    onChange={handleChange}
                    required
                  />
                  {errors.landmark && <p className="error">{errors.landmark}</p>}
                </div>
              </div>

              <div className="form-group-row">
                <div className="form-group-checkbox">
                  <input
                    type="checkbox"
                    id="cashOnDelivery"
                    name="cashOnDelivery"
                    checked={formData.cashOnDelivery}
                    onChange={handleCheckboxChange}
                    required
                  />
                  <label htmlFor="cashOnDelivery">Cash on Delivery</label>
                  {errors.cashOnDelivery && <p className="error">{errors.cashOnDelivery}</p>}
                </div>
              </div>

              <button type="submit" className="login-button">
                {loading ? 'Please wait...' : 'Submit'} {/* Button text changes based on loading state */}
              </button>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalForm;
