import React, { useContext, useEffect, useState } from 'react';
import { CartContext } from './CartContext';
import { AuthContext } from './AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { firestore } from './firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import './Cart.css';
import CheckoutModal from './CheckoutModal';

const Cart = () => {
    const { cartItems, setCartItems, updateCartItemQuantity, removeCartItem } = useContext(CartContext);
    const { user, isLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchCartItems = async () => {
            if (isLoggedIn && user) {
                try {
                    const q = query(collection(firestore, 'Cart'), where('userId', '==', user.uid));
                    const querySnapshot = await getDocs(q);
                    const fetchedItems = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }));
                    setCartItems(fetchedItems);
                } catch (error) {
                    console.error('Error fetching cart items from Firestore: ', error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchCartItems();
    }, [isLoggedIn, user, setCartItems]);

    const calculateTotals = () => {
        let totalItems = 0;
        let totalPrice = 0;

        if (cartItems && cartItems.length > 0) {
            cartItems.forEach(item => {
                totalItems += item.quantity;
                totalPrice += item.productPrice * item.quantity;
            });
        }

        return {
            totalItems,
            totalPrice
        };
    };

    const totals = calculateTotals();

    const handleCheckoutClick = () => {
        if (isLoggedIn) {
            navigate('/order');
        } else {
            setIsModalOpen(true);
        }
    };

    if (loading) {
        return <div className="cart">Loading...</div>;
    }

    if (!cartItems || cartItems.length === 0) {
        return <div className="cart">Your cart is empty.</div>;
    }

    return (
        <div className="cart">
            <h2>Your Cart</h2>
            <div className="cart-content">
                <div className="cart-items">
                    {cartItems.map((item, index) => (
                        <div className="cart-item" key={index}>
                            {item.images && item.images.length > 0 ? (
                                <img src={item.images[0]} alt={item.name} className="cart-item-image" />
                            ) : (
                                <img src="placeholder.jpg" alt="placeholder" className="cart-item-image" />
                            )}
                            <div className="cart-item-details">
                                <h3>{item.productName}</h3>
                                <p>Color: {item.selectedColor}, Size: {item.selectedSize}</p>
                                <p>Price: Rs. {item.productPrice}/-</p>
                                <p>Cut Price: Rs. {item.cuttedPrice}/-</p>
                                <div className="quantity-controls">
                                    <button onClick={() => updateCartItemQuantity(item.id, item.quantity - 1, item.selectedSize)} disabled={item.quantity <= 1}>-</button>
                                    <span>{item.quantity}</span>
                                    <button onClick={() => updateCartItemQuantity(item.id, item.quantity + 1, item.selectedSize)}>+</button>
                                </div>
                                <button onClick={() => removeCartItem(item.id, item.selectedSize)} className="remove-button">Remove</button>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="cart-summarys">
                    <h3>Summary</h3>
                    <p>Total Items: {totals.totalItems}</p>
                    <p>Total Price: Rs. {totals.totalPrice}/-</p>
                    <button className="checkout-button" onClick={handleCheckoutClick}>Checkout</button>
                    <Link to="/" className="continue-shopping">Continue Shopping</Link>
                </div>
            </div>
            <CheckoutModal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} />
        </div>
    );
};

export default Cart;
