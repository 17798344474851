import React from 'react';
import './Rating.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const Rating = ({ rating, reviews }) => {
  const maxRating = 5;
  const filledStars = Math.round(rating);

  // Calculate percentage of filled stars for each rating level
  const ratingLevels = [
    { stars: 5, percentage: calculatePercentage(reviews, 5) },
    { stars: 4, percentage: calculatePercentage(reviews, 4) },
    { stars: 3, percentage: calculatePercentage(reviews, 3) },
    { stars: 2, percentage: calculatePercentage(reviews, 2) },
    { stars: 1, percentage: calculatePercentage(reviews, 1) }
  ];

  function calculatePercentage(reviews, star) {
    const totalReviews = reviews.length;
    if (totalReviews === 0) return 0;
    
    const starCount = reviews.filter(r => r === star).length;
    return (starCount / totalReviews) * 100;
  }

  return (
    <div className="rating-container">
      <div className="ratings">
        <div className="average-rating">
          <h3 style={{ fontFamily: 'Barlow', fontSize: '24px' }}>Average User Rating</h3>
          <div className="rating">
            <span className="rating-text">
              {rating !== undefined ? rating.toFixed(1) : '0.0'} / {maxRating}
            </span>
            <br />
            {Array.from({ length: maxRating }, (_, index) => (
              <FontAwesomeIcon
                icon={faStar}
                key={index}
                className={index < filledStars ? 'star filled' : 'star'}
              />
            ))}
          </div>
        </div>

        <div className="rating-breakdown">
          <h3 style={{ fontFamily: 'Barlow', fontSize: '24px' }}>Rating Breakdown</h3>
          <div className="progress-bars">
            {ratingLevels.map((level, index) => (
              <div key={index} className="progress-bar">
                <span className="stars">{level.stars} *</span>
                <div className="progress">
                  <div
                    className="progress-bar-fill"
                    style={{ width: `${level.percentage}%` }}
                  ></div>
                </div>
                <span className="percentage">{`${level.percentage.toFixed(1)}`}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rating;
