// src/AboutUs.js
import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
    return (
        <div className="about-us">
            <div className="about-us__header">
                <h1>About Us</h1>
            </div>
            <div className="about-us__content">
                <div className="about-us__row">
                    <div className="about-us__image">
                        <img src="about.png" alt="About Us" />
                    </div>
                    <div className="about-us__text">
                        <section className="about-us__section">
                            <h2>Our Mission</h2>
                            <p>Our mission is to deliver the best products and services to our customers. We are committed to excellence and strive to exceed expectations.</p>
                        </section>
                        <section className="about-us__section">
                            <h2>Our Team</h2>
                            <p>We have a diverse team of skilled professionals who are passionate about what they do. Together, we work hard to achieve our goals and serve our customers.</p>
                        </section>
                        <section className="about-us__section">
                            <h2>Our Story</h2>
                            <p>Founded in 2024, our company has grown from a small startup to a leading player in the industry. Our journey has been exciting and we look forward to continued success.</p>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
