import React, { useState, useContext, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import DOMPurify from 'dompurify';
import { doc, getDoc, setDoc, increment, updateDoc, collection } from 'firebase/firestore';
import { firestore } from './firebase';
import { CartContext } from './CartContext';
import { AuthContext } from './AuthContext';
import ModalForm from './ModalForm';
import Rating from './Rating'; // Import the Rating component
import Tabs from './Tabs';
import './ProductSection.css';

const ProductLoader = () => (
  <ContentLoader viewBox="0 0 400 160" speed={2}>
    {/* Add your loader shapes here */}
  </ContentLoader>
);

const ProductSection = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { addToCart } = useContext(CartContext);
  const { isLoggedIn } = useContext(AuthContext);

  const [product, setProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedSize, setSelectedSize] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [loading, setLoading] = useState(true);
  const visitTrackingRef = useRef(null);

  useEffect(() => {
    const fetchProduct = async () => {
      const pathSegments = location.pathname.split('/');
      const productId = pathSegments[pathSegments.length - 1];

      if (!productId) {
        console.error('Invalid productId');
        return;
      }

      try {
        const productDoc = doc(firestore, 'products', productId);
        const productSnapshot = await getDoc(productDoc);

        if (productSnapshot.exists()) {
          const productData = productSnapshot.data();
          setProduct({ ...productData, id: productId });
          setSelectedImage(productData.images[0]);
          setSelectedColor(productData.colors[0]);
          setSelectedSize(productData.sizes[0]);
        } else {
          console.error('Product ID not found');
        }
      } catch (error) {
        console.error('Error fetching product:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [location.pathname]);

  useEffect(() => {
    const trackUserVisit = async () => {
      const pathSegments = location.pathname.split('/');
      const productId = pathSegments[pathSegments.length - 1];

      if (!productId) return;

      const visitStartTime = new Date();
      visitTrackingRef.current = visitStartTime;

      const analyticsData = {
        timestamp: visitStartTime.toISOString(),
        referrer: document.referrer || 'Direct',
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        language: navigator.language,
        startTime: visitStartTime.getTime(),
        endTime: null,
        timeSpent: null,
      };

      try {
        const productTrackingDoc = doc(firestore, 'productTracking', productId);

        await setDoc(productTrackingDoc, { visits: increment(1) }, { merge: true });

        const visitDetailsDoc = doc(
          collection(firestore, 'productTracking', productId, 'visitDetails'),
          visitStartTime.toISOString()
        );
        await setDoc(visitDetailsDoc, analyticsData);
      } catch (error) {
        console.error('Error tracking user visit:', error);
      }
    };

    trackUserVisit();

    return () => {
      const trackTimeSpent = async () => {
        const pathSegments = location.pathname.split('/');
        const productId = pathSegments[pathSegments.length - 1];

        if (!productId || !visitTrackingRef.current) return;

        const visitEndTime = new Date();
        const timeSpent = visitEndTime.getTime() - visitTrackingRef.current.getTime();

        try {
          const productTrackingDoc = doc(firestore, 'productTracking', productId);

          await setDoc(productTrackingDoc, { totalTimeSpent: increment(timeSpent) }, { merge: true });

          const visitDetailsDoc = doc(
            collection(firestore, 'productTracking', productId, 'visitDetails'),
            visitTrackingRef.current.toISOString()
          );
          await updateDoc(visitDetailsDoc, {
            endTime: visitEndTime.getTime(),
            timeSpent,
          });
        } catch (error) {
          console.error('Error tracking time spent:', error);
        }
      };

      trackTimeSpent();
    };
  }, [location.pathname]);

  const handleBuyNow = async () => {
    const pathSegments = location.pathname.split('/');
    const productId = pathSegments[pathSegments.length - 1];

    // Track "Buy Now" clicks before opening the modal
    if (productId) {
      const productTrackingDoc = doc(firestore, 'productTracking', productId);

      try {
        await updateDoc(productTrackingDoc, {
          buyNowClicks: increment(1),
        });
      } catch (error) {
        console.error('Error tracking "Buy Now" clicks:', error);
      }
    }

    // Check if the user is logged in and handle accordingly
    if (!isLoggedIn) {
      setIsModalOpen(true);
    } else {
      await handleAddToCart();
      navigate('/cart');
    }
  };


  const handleAddToCart = async () => {
    const productToAdd = {
      ...product,
      selectedColor,
      selectedSize,
      quantity,
      currentPrice: product.productPrice,
      cuttedPrice: product.cuttedPrice,
    };

    try {
      await addToCart(productToAdd);
      navigate('/cart');
    } catch (error) {
      console.error('Error adding product to cart:', error);
    }
  };

  const renderDescription = (description) => {
    const sanitizedDescription = DOMPurify.sanitize(description || '');
    const words = sanitizedDescription.split(' ');
    if (words.length > 50 && !showFullDescription) {
      return (
        <>
          <div dangerouslySetInnerHTML={{ __html: words.slice(0, 50).join(' ') + '...' }} />
          <button className="more-button" onClick={() => setShowFullDescription(true)}>More</button>
        </>
      );
    }
    return <div dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />;
  };

  return (
    <div className="product-container">
      <div className="product-section">
        {loading ? (
          <ProductLoader />
        ) : (
          <>
            <div className="thumbnail-gallery">
              {product.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={product.productName}
                  className={`thumbnail-image ${image === selectedImage ? 'selected' : ''}`}
                  onClick={() => setSelectedImage(image)}
                />
              ))}
            </div>
            <div className="image-gallery">
              <img src={selectedImage} alt={product.productName} className="product-image" />
            </div>
            <div className="product-details">
              <small className="store-name">My Store</small>
              <h1 className="product-name">
                {product.productName} - {selectedColor}
              </h1>
              <p className="rating-review">2 Pcs of pack @499 Only</p>
              <p className="price">
                <span className="current-price">₹{product.productPrice}</span>
                <span className="cut-price">₹{product.cuttedPrice}</span>
                <span className="discount">
                  ({(((product.cuttedPrice - product.productPrice) / product.cuttedPrice) * 100).toFixed(0)}% OFF)
                </span>
              </p>
              <p className="tax-info">Inclusive of all taxes</p>
              <p className="rating-review">5,136 ratings and 324 reviews</p>
              <div className="sizes">
                <label>Size: </label>
                <select
                  value={selectedSize}
                  onChange={(e) => setSelectedSize(e.target.value)}
                  className="size-dropdown"
                >
                  {product.sizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              </div>
              <div className="colors">
                <label>Color: </label>
                {product.colors.map((color, index) => (
                  <button
                    key={index}
                    className={`color-button ${color === selectedColor ? 'selected' : ''}`}
                    style={{ backgroundColor: color }}
                    onClick={() => setSelectedColor(color)}
                  ></button>
                ))}
              </div>
              <div className="quantity">
                <label>Qty: </label>
                <input
                  type="number"
                  value={quantity}
                  min="1"
                  onChange={(e) => setQuantity(parseInt(e.target.value))}
                />
              </div>
              <div className="delivery">
                <h3>Delivery</h3>
                <p>Delivery 2/3 days</p>
              </div>
              <div className="action-buttons">
                {/* "Add to Cart" button is hidden */}
                <button className="buy-now-button" onClick={handleBuyNow}>
                  Buy Now
                </button>
              </div>
              <div className="product-description">
                <h3>DESCRIPTION</h3>
                {renderDescription(product.productDesc)}
              </div>
              <Rating rating={product.averageRating} reviews={product.reviews || []} />
            </div>
          </>
        )}
      </div>
      <Tabs />
      <ModalForm
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        product={product}
      />
    </div>
  );
};

export default ProductSection;
