// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppContainer from './AppWrapper'; // Import AppContainer instead of App
import { AuthProvider } from './AuthContext';

ReactDOM.render(
  <AuthProvider>
    <AppContainer />
  </AuthProvider>,
  document.getElementById('root')
);
