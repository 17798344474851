import React, { createContext, useState, useEffect, useContext } from 'react';
import { firestore } from './firebase';
import { collection, addDoc, doc, updateDoc, deleteDoc, query, where, getDocs } from 'firebase/firestore';
import { AuthContext } from './AuthContext';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState([]);
    const { isLoggedIn, user } = useContext(AuthContext);

    useEffect(() => {
        const fetchCartItems = async () => {
            if (isLoggedIn && user) {
                // Merge localStorage with Firestore after login
                await mergeLocalCartWithFirestore();

                // Fetch from Firestore
                const q = query(collection(firestore, 'Cart'), where('userId', '==', user.uid));
                const querySnapshot = await getDocs(q);
                const fetchedItems = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setCartItems(fetchedItems);
            } else {
                // Fetch from localStorage
                const localCart = JSON.parse(localStorage.getItem('cartItems')) || [];
                setCartItems(localCart);
            }
        };

        fetchCartItems();
    }, [isLoggedIn, user]);

    const syncLocalStorage = (updatedCart) => {
        localStorage.setItem('cartItems', JSON.stringify(updatedCart));
    };

    const mergeLocalCartWithFirestore = async () => {
        const localCart = JSON.parse(localStorage.getItem('cartItems')) || [];

        for (const item of localCart) {
            // Add each item from localStorage to Firestore
            await addDoc(collection(firestore, 'Cart'), {
                userId: user.uid,
                ...item,
            });
        }

        // Clear localStorage after merging
        localStorage.removeItem('cartItems');
    };

    const addToCart = async (product) => {
        const updatedCart = [...cartItems, product];

        if (isLoggedIn && user) {
            // Add to Firestore
            await addDoc(collection(firestore, 'Cart'), {
                userId: user.uid,
                ...product,
            });
        } else {
            // Update localStorage
            syncLocalStorage(updatedCart);
        }

        setCartItems(updatedCart);
    };

    const updateCartItemQuantity = async (productId, quantity, selectedSize) => {
        const updatedCart = cartItems.map(item =>
            item.id === productId && item.selectedSize === selectedSize ? { ...item, quantity } : item
        );

        if (isLoggedIn && user) {
            const q = query(collection(firestore, 'Cart'), where('userId', '==', user.uid), where('id', '==', productId));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc) => {
                await updateDoc(doc.ref, { quantity });
            });
        } else {
            // Update localStorage
            syncLocalStorage(updatedCart);
        }

        setCartItems(updatedCart);
    };

    const removeCartItem = async (productId, selectedSize) => {
        const updatedCart = cartItems.filter(item => !(item.id === productId && item.selectedSize === selectedSize));

        if (isLoggedIn && user) {
            const q = query(collection(firestore, 'Cart'), where('userId', '==', user.uid), where('id', '==', productId));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc) => {
                await deleteDoc(doc.ref);
            });
        } else {
            // Update localStorage
            syncLocalStorage(updatedCart);
        }

        setCartItems(updatedCart);
    };

    const clearCart = async () => {
        if (isLoggedIn && user) {
            // Clear Firestore Cart
            const q = query(collection(firestore, 'Cart'), where('userId', '==', user.uid));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc) => {
                await deleteDoc(doc.ref);
            });
        }
        // Clear local cart state
        setCartItems([]);
        syncLocalStorage([]);
    };

    return (
        <CartContext.Provider value={{ cartItems, addToCart, updateCartItemQuantity, removeCartItem, clearCart }}>
            {children}
        </CartContext.Provider>
    );
};
