// src/ContactUs.js
import React from 'react';
import './ContactUs.css';
import { FaPhoneAlt } from 'react-icons/fa';

const ContactUs = () => {
    return (
        <div className="contact-us">
            <div className="contact-us__header">
                <h1>Contact Us</h1>
            </div>
            <div className="contact-us__content">
                <div className="contact-us__form">
                    <form>
                        <label htmlFor="name">Name:</label>
                        <input type="text" id="name" name="name" required />

                        <label htmlFor="email">Email:</label>
                        <input type="email" id="email" name="email" required />

                        <label htmlFor="message">Message:</label>
                        <textarea id="message" name="message" rows="4" required></textarea>

                        <button type="submit">Send</button>
                    </form>
                </div>
                <div className="contact-us__divider"></div>
                <div className="contact-us__details">
                    <p>If you have any questions or need further information, please feel free to contact us.</p>
                    <p>Email: support_zivamisportsbra@gmail.com</p>
                    <p>Phone: +91 926 612 7640</p>
                    
                    
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
